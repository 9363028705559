exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-demo-lectures-js": () => import("./../../../src/pages/demo-lectures.js" /* webpackChunkName: "component---src-pages-demo-lectures-js" */),
  "component---src-pages-gallery-detail-js": () => import("./../../../src/pages/gallery-detail.js" /* webpackChunkName: "component---src-pages-gallery-detail-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-teamdetail-js": () => import("./../../../src/pages/teamdetail.js" /* webpackChunkName: "component---src-pages-teamdetail-js" */),
  "component---src-pages-upper-advantage-js": () => import("./../../../src/pages/upper-advantage.js" /* webpackChunkName: "component---src-pages-upper-advantage-js" */)
}

